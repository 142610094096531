import revive_payload_client_Y77o7W6T9Q from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_c5sdsd5b7mmdmbhxa3wmewhnk4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pnswrX7wY8 from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_c5sdsd5b7mmdmbhxa3wmewhnk4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oy4a352kPY from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_c5sdsd5b7mmdmbhxa3wmewhnk4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_fPjgj3YOh8 from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/plugins/sentry.js";
import logger_iy0pQFv0EJ from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/plugins/logger.js";
import payload_client_ZpiJsk2UIn from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_c5sdsd5b7mmdmbhxa3wmewhnk4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_6V9KT2x5UE from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_c5sdsd5b7mmdmbhxa3wmewhnk4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_afyjD5aBWh from "/opt/node_app/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.3_rollup@4.14.1_typescript@5.4.5_vue@3.4.21_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/node_app/app/.nuxt/components.plugin.mjs";
import prefetch_client_M24WcO8piA from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_c5sdsd5b7mmdmbhxa3wmewhnk4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import hydrated_S7xfX07ecK from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/plugins/hydrated.js";
import tooltip_OZbsQgoT76 from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/plugins/tooltip.js";
import gtag_iXIwfsWF97 from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/plugins/gtag.js";
import theme_dUzlenks94 from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/plugins/theme.js";
import middleware_ULUE89Ga5j from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/plugins/middleware.js";
import metrics_GpqkCbdYiH from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/plugins/metrics.js";
import plugin_Jr1CwJz1D1 from "/opt/node_app/app/src/modules/gtm/runtime/plugin.ts";
import chunk_reload_client_YEdA5OjBt4 from "/opt/node_app/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.0_axios@1.6.8_encodi_c5sdsd5b7mmdmbhxa3wmewhnk4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import logging_82983bmFHh from "/opt/node_app/app/src/plugins/logging.ts";
export default [
  revive_payload_client_Y77o7W6T9Q,
  unhead_pnswrX7wY8,
  router_oy4a352kPY,
  sentry_fPjgj3YOh8,
  logger_iy0pQFv0EJ,
  payload_client_ZpiJsk2UIn,
  check_outdated_build_client_6V9KT2x5UE,
  plugin_vue3_afyjD5aBWh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_M24WcO8piA,
  hydrated_S7xfX07ecK,
  tooltip_OZbsQgoT76,
  gtag_iXIwfsWF97,
  theme_dUzlenks94,
  middleware_ULUE89Ga5j,
  metrics_GpqkCbdYiH,
  plugin_Jr1CwJz1D1,
  chunk_reload_client_YEdA5OjBt4,
  logging_82983bmFHh
]