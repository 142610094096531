import { default as TemplatePageLYyt9eHZKMMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/pages/TemplatePage.vue?macro=true";
import { default as KitchenSink8wz6HXS2UMMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/pages/KitchenSink.vue?macro=true";
import { default as BackofficeComponentPreviewFeumi4GZRnMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/pages/BackofficeComponentPreview.vue?macro=true";
import { default as ReadyProbeLIHWi7wOaTMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/pages/ReadyProbe.vue?macro=true";
export default [
  {
    name: TemplatePageLYyt9eHZKMMeta?.name ?? "template-page",
    path: TemplatePageLYyt9eHZKMMeta?.path ?? "/:slug(.*)*",
    meta: { ...(TemplatePageLYyt9eHZKMMeta || {}), ...{"middleware":["headless-middleware"]} },
    alias: TemplatePageLYyt9eHZKMMeta?.alias || [],
    redirect: TemplatePageLYyt9eHZKMMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/pages/TemplatePage.vue").then(m => m.default || m)
  },
  {
    name: KitchenSink8wz6HXS2UMMeta?.name ?? "kitchen-sink",
    path: KitchenSink8wz6HXS2UMMeta?.path ?? "/_kitchen-sink",
    meta: { ...(KitchenSink8wz6HXS2UMMeta || {}), ...{"layout":false,"pageTransition":false,"layoutTransition":false} },
    alias: KitchenSink8wz6HXS2UMMeta?.alias || [],
    redirect: KitchenSink8wz6HXS2UMMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/pages/KitchenSink.vue").then(m => m.default || m)
  },
  {
    name: BackofficeComponentPreviewFeumi4GZRnMeta?.name ?? "umb-backoffice-component-preview",
    path: BackofficeComponentPreviewFeumi4GZRnMeta?.path ?? "/umb-backoffice-component-preview",
    meta: { ...(BackofficeComponentPreviewFeumi4GZRnMeta || {}), ...{"layout":false,"pageTransition":false,"layoutTransition":false} },
    alias: BackofficeComponentPreviewFeumi4GZRnMeta?.alias || [],
    redirect: BackofficeComponentPreviewFeumi4GZRnMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/pages/BackofficeComponentPreview.vue").then(m => m.default || m)
  },
  {
    name: ReadyProbeLIHWi7wOaTMeta?.name ?? "nuxt-ready-probe",
    path: ReadyProbeLIHWi7wOaTMeta?.path ?? "/_probes/nuxt/ready",
    meta: { ...(ReadyProbeLIHWi7wOaTMeta || {}), ...{"layout":false,"pageTransition":false,"layoutTransition":false} },
    alias: ReadyProbeLIHWi7wOaTMeta?.alias || [],
    redirect: ReadyProbeLIHWi7wOaTMeta?.redirect,
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.699_encoding@0.1.13_ioredis@5.3.2_magicast@0.3.3_rollup@4.14.1_typescript@5._befj2ymq3qk5whxm6cu4fs22dy/node_modules/@edco/apollo/dist/runtime/pages/ReadyProbe.vue").then(m => m.default || m)
  }
]